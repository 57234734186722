$color: #000000;
$mb: 1023px;
$desk: 1024px;
$color-red: #D02E26;
$hdFontFamily: '5ka Sans Design';
$textFontFamily: '5ka Sans Design';

.modal__close {
  width: 4.1rem;
  height: 4.1rem;
  position: absolute;
  cursor: pointer;
  top: 1.6rem;
  right: 1.6rem;
  opacity: 1;
  z-index: 5;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease;

  @media screen and (max-width: $mb){
    right: 1rem;
    top: 1rem;
    width: 3rem;
    height: 3rem;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    overflow: visible;
  }

  &:hover {
    opacity: 0.7;
  }
}

.modal{
  &__backdrop {
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 400;
    backdrop-filter: blur(1rem);
  }

  &__content {
    position: fixed;
    z-index: 500;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;

    &-box {
      margin-top: 2.4rem;
      margin-bottom: 2.4rem;
      position: relative;

      @media screen and (max-width: $mb){
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &-area {
      min-height: 100%;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }
}

.modal__form {
  text-align: left;
}

.modal__form-text {
  color: #000;
  font-family: $textFontFamily;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;

  @media screen and (max-width: $mb){
    font-size: 1.4rem;
  }

  a {
    color: #061E83 !important;
    border-bottom: 1px solid transparent;
    will-change: border-color;
    transition: border-color 0.2s ease;
    cursor: pointer;

    &:hover {
      border-bottom: 1px solid rgba(#061E83, 0.65);
    }
  }
}

.btn--full {
  width: 100%;
}

.modal__form {
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: $mb){
    width: 100%;
  }
}

.duration-200 {
  transition-duration: 200ms;
}

.duration-300 {
  transition-duration: 300ms;
}

.duration-150 {
  transition-duration: 150ms;
}

.duration-500 {
  transition-duration: 500ms;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 100;
}

.modal__content {
  overflow-x: hidden;
}

.modal__content-box {
  margin: 0 !important;
  background-color: #FFFFFF;
  width: 100%;
}

.modal__checkbox-it {
  &:not(:last-child){
    margin-bottom: 3rem;

    @media screen and (max-width: $mb){
      margin-bottom: 1.4rem;
    }
  }
}

.modal__icon {
  background: $color;
  width: 6rem;
  height: 6rem;
  font-size: 3.2rem;
  border-radius: 50%;
  font-weight: 700;
  color: #fff;
  margin: 0 auto 2.4rem;
}

.modal__subtitle {
  color: #000;
  text-align: center;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 120%;
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0 !important;
  }

  @media screen and (max-width: $mb){

  }
}

.modal__ct {
  position: relative;
  z-index: 3;
}

.modal__form-submit{
  width: 100%;
}

.modal__content-area {
  display: flex;

  @media screen and (max-width: $mb){
    align-items: center !important;
  }
}

.modal__content-box {
  max-width: 70rem;
  padding: 0;
  border-radius: 3rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background: #FEAA41;

  @media screen and (max-width: $mb){
    max-width: 34rem;
    border-radius: 1.6rem;
  }

  &-bl {
    position: relative;
    padding: 5rem 0;

    @media screen and (max-width: $mb){
      padding: 3rem 1rem 1rem;
    }
  }
}

.modal__content-area {
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media screen and (max-width: $mb){
    padding-top: 0;
    padding-bottom: 0;
  }
}

.modal__title {
  color: #000000;
  text-align: center;
  text-transform: uppercase;
  font-family: $hdFontFamily;
  font-size: 5rem;
  font-weight: 900;
  line-height: 100%;
  letter-spacing: -0.05rem;

  &--small {
    font-size: 2.9rem;
  }

  @media screen and (max-width: $mb){
    font-size: 2.4rem;
    letter-spacing: -0.024rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.modal__hint {
  color: #390E00;
  font-size: 1.4rem;
  letter-spacing: -0.014rem;
  line-height: 110%;
  text-align: left;

  a {
    color: #fff !important;
    border-bottom: 1px solid rgba(#fff, 0.5);
    will-change: border-color;
    transition: border-color 0.2s ease;

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
}


@media screen and (min-width: $desk){
  .modal__subtitle--indent {
    margin-right: -6rem;
    margin-left: -6rem;
  }
}

.modal__checkboxes {
  .error__message {
    margin-top: 1.2rem;
    margin-left: 2rem;
  }
}

.modal--pass,
.modal--prize{
  .modal__title {
    margin-left: -4rem;
    margin-right: -4rem;

    @media screen and (max-width: $mb){
      margin-left: 0;
      margin-right: 0;
      font-size: 2.6rem;
    }
  }
}

.modal__btns--full {
  .modal__btn {
    &:not(:last-child){
      margin-right: 0;
      margin-bottom: 2rem;
    }
  }
}


.modal__btn {
  .btn {
    padding-left: 4rem;
    padding-right: 4rem;

    @media screen and (max-width: $mb){
      width: 100%;
    }
  }

  @media screen and (max-width: $mb){
    width: 100%;
  }

  &:not(:last-child){
    margin-right: 2rem;

    @media screen and (max-width: $mb){
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
}

.modal__prize-img {
  width: 18rem;
  position: relative;
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: $mb){
    width: 15rem;
  }

  img {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
  }
}

.form__hint {
  color: #fff;
  font-size: 1.6rem;
  line-height: 130%;
  text-align: left;

  @media screen and (max-width: $mb){
    font-size: 1.2rem;
  }
}
.modal__checkboxes {
  padding-top: 0.8rem;
  padding-bottom: 0.2rem;
}

.modal__bottom-btn {
  margin-bottom: 2.4rem;
}

.modal__link {
  color: #390E00;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  text-transform: uppercase;

  @media screen and (max-width: $mb){
    font-size: 1.6rem;
  }

  a {
    color: #ffffff !important;
    cursor: pointer;
    border-bottom: 1px solid #ffffff;
    will-change: border-color;
    transition: border-color 0.2s ease;

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
}

.modal--info {
  .modal__content-box {
    max-width: 70rem;

    @media screen and (max-width: $mb){
      max-width: 30rem;
    }

    &:not(:last-child){
      margin-bottom: 3.2rem;

      @media screen and (max-width: $mb){

      }
    }
  }

  .modal__title {
    font-size: 3.5rem;
    line-height: 120%;

    @media screen and (max-width: $mb){
      font-size: 2.2rem;
    }
  }

  .modal__subtitle {
    margin-right: -1rem;
    margin-left: -1rem;

    @media screen and (max-width: $mb){
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.modal__subtitle--indent {
  margin-bottom: 2.4rem;
}

.modal__subtitle--indent2 {
  margin-bottom: 0.8rem;
}

.modal__text {
  color: #000000;
  text-align: center;
  font-family: $textFontFamily;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 120%;

  @media screen and (max-width: $mb){
    font-size: 1.6rem;
  }
}

.modal__ct {
  .btn {
    max-width: 36rem;
    margin-right: auto;
    margin-left: auto;
  }
}

.modal__ct-box {
  padding-left: 4rem;
  padding-right: 4rem;

  @media screen and (max-width: $mb){
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &:not(:last-child){
    margin-bottom: 3rem;

    @media screen and (max-width: $mb){
      margin-bottom: 2rem;
    }
  }
}

.form__field {
  position: relative;
}

.icon__success {
  width: 8.5rem;
  height: 8.5rem;
  margin: 0 auto;
  //background: url("../img/success.png") no-repeat center;
  background-size: contain;
  display: block;
}

.modal--info {
  .btn-ct {
    max-width: 38rem;
    margin-right: auto;
    margin-left: auto;
  }
}

.modal__descr {
  text-align: center;
  font-size: 1.6rem;
  line-height: 120%;
  margin-bottom: 1rem;
}

.modal__hint--large {
  font-size: 1.6rem;
}

.modal__ct-bottom {
  border-top: 1px solid #EAEAEA;
  padding-top: 2rem;

  .modal__form-text {
    &:not(:last-child){
      margin-bottom: 1rem;
    }
  }
}

.modal__ct-img {
  font-size: 0;
  border: 1px solid #EAEAEA;
  border-radius: 1rem;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
  }

  &:not(:last-child){
    margin-bottom: 1.6rem;
  }
}

.modal--contest {
  .modal__content-box {
    max-width: 116rem;
    border-radius: 9rem;

    @media screen and (max-width: $mb){
      max-width: 30rem;
      border-radius: 1.6rem;
    }
  }

  .modal__content-box-bl {
    padding: 0;
  }

  .modal__contest-title {
    color: #021D7F;
    font-size: 4.6rem;
    font-weight: 900;
    line-height: 1;
    letter-spacing: 0.092rem;
    text-transform: uppercase;
    margin-bottom: 1.6rem;

    @media screen and (max-width: $mb){
      font-size: 3rem;
    }
  }

  .modal__contest-text {
    text-align: center;
    color: #021D7F;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.8rem;
    letter-spacing: 0.036rem;
    margin-bottom: 1.2rem;

    @media screen and (max-width: $mb){
      font-size: 1.4rem;
      line-height: 140%;
      letter-spacing: 0.024rem;
    }

    p {
      margin: 0 0 1.6rem 0;
    }

    & > * :last-child {
      margin-bottom: 0 !important;
    }
  }

  .modal__close {
    width: 6.4rem;
    height: 3.6rem;
    top: 4.3rem;
    right: 6.3rem;

    @media screen and (max-width: $mb){
      width: 3rem;
      height: 3rem;
      top: 1rem;
      right: 1rem;
    }
  }
}

.modal--result {
  .modal__content-box {
    max-width: 80rem;

    @media screen and (max-width: $mb){
      max-width: 100vw;
      border-radius: 0;
      margin: 0 !important;

      .modal__content-box-bl {
        padding: 3.5rem 2rem 3rem;
      }

      .modal__close {
        display: none !important;
      }

      .modal__ct {
        position: relative;
      }
    }
  }

  .modal__ct-box {
    padding-right: 0;
    padding-left: 0;
  }

  .modal__ct {
    max-width: 60rem;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (max-width: $mb){
  .modal--feedback {
    .modal__title {
      span {
        display: inline;
      }
    }
  }

  .form__it--attach span {
    font-size: 1.4rem;
  }

  .modal--prize {
    .modal__text {
      font-size: 1.6rem;

      br {
        display: none;
      }
    }
  }

  .modal__content-box {
    margin-top: 2rem !important;
    margin-bottom: 12rem !important;
  }


  .modal__subtitle {
    font-size: 1.4rem;
  }
}