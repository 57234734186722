@font-face{
    font-family:"5ka Sans Design";
    src:local("5ka Sans Design"),
    url("../fonts/5kaSansDesign-Light.woff2") format("woff2");
    font-weight:300;
    font-style:normal;
    font-display:swap
}

@font-face{
    font-family:"5ka Sans Design";
    src:local("5ka Sans Design"),
    url("../fonts/5kaSansDesign-Regular.woff2") format("woff2");
    font-weight:400 500;
    font-style:normal;
    font-display:swap
}

@font-face{
    font-family:"5ka Sans Design";
    src:local("5ka Sans Design"),
    url("../fonts/5kaSansDesign-SemiBold.woff2") format("woff2");
    font-weight:600 700;
    font-style:normal;
    font-display:swap
}

@font-face{
    font-family:"5ka Sans Design";
    src:local("5ka Sans Design"),
    url("../fonts/5kaSansDesign-Black.woff2") format("woff2");
    font-weight:800;
    font-style:normal;
    font-display:swap
}