$textFontFamily: '5ka Sans Design';
$color: #000000;
$mb: 1023px;
$desk: 1024px;

*{
  margin: 0;
  padding: 0;
  outline: none !important;
  box-sizing: border-box;
  text-decoration: none !important;
}

html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  font-family: $textFontFamily;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: normal;
  color: $color;
}

body {
  background: #FEAA41;
}

body {
  overscroll-behavior: none;
  position: relative;
}

html {
  font-size: 0.69444vw;
}

.justify-wrap{
  text-align: justify;
  font-size: 0;
  text-justify: newspaper;
}

.justify-wrap:after{
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 0;
}

[placeholder]:focus::-webkit-input-placeholder {
  color: transparent !important;
}

[placeholder]:focus:-moz-placeholder {
  color: transparent !important;
}

[placeholder]:focus::-moz-placeholder {
  color: transparent !important;
}

[placeholder]:focus:-ms-input-placeholder {
  color: transparent !important;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: " ";
}

.clearfix:after {
  clear: both;
}

.container{
  margin: 0 auto;
  box-sizing: border-box;
  min-width: 320px;
  max-width: 100%;
  width: 100%;
  padding-right: 6rem;
  padding-left: 6rem;

  @media screen and (max-width: $mb){
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.row {
  margin-left: -2rem;
  margin-right: -2rem;
}

.col {
  padding-right: 2rem;
  padding-left: 2rem;
}

.wrapper{
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  min-width: 320px;
  min-height: 100vh;
}

.hidden {
  display: none !important;
}

.anchor {
  position: absolute;
  bottom: 100%;
  height: 5rem;
  left: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;

  @media screen and (max-width: $mb){
    height: 5rem;
  }
}

.sc__delivery {
  .anchor {
    margin-bottom: 7rem;

    @media screen and (max-width: $mb){
      margin-bottom: 4rem;
    }
  }
}

.anim__box {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  iframe {
    height: 99.99%;
    width: 99.99%;
    display: block;
    overflow:hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &:after{
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: 2;
  }
}

.sc__full {
  height: 100vh;
}

.header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 200;
  top: 0;
  background: #6EA040;
  box-shadow: 0 0.4rem 0.6rem rgba(0, 0, 0, 0.15);
  border-radius: 0 0 1.5rem 1.5rem;
  height: 8rem;

  @media screen and (max-width: $mb){
    height: 5rem;
    box-shadow: 0 0.4rem 0.2rem rgba(0, 0, 0, 0.15);
    border-radius: 0 0 1.2rem 1.2rem;
  }

  .container {
    height: 100%;
  }
}

.logo {
  width: 17.3rem;
  cursor: pointer;

  @media screen and (max-width: $mb){
    width: 10.4rem;
    position: relative;
    z-index: 10;
  }

  img {
    display: block;
    width: 100%;
  }
}

.sc__promo {
  position: relative;

  .container {
    padding-top: 11rem;
    position: relative;
    z-index: 5;
    padding-bottom: 9rem;

    @media screen and (max-width: $mb){
      padding-top: 7.7rem;
      padding-bottom: 6rem;
    }
  }

  &.loaded {
    .promo__prize {
      transform: scale(1);
    }

    .promo__card {
      &--1 {
        transform: rotate(-50.843deg);
        margin-left: -76rem;
      }

      &--2 {
        transform: rotate(34.113deg);
        margin-left: 52.8rem;
        top: 40.2rem;
      }

      &--3 {
        top: -8.2rem;
        transform: rotate(-34.079deg);
        margin-left: -51.8rem;
      }
    }
  }
}

.sc__promo-bg--1 {
  background: linear-gradient(185deg, #4DACD4 19.35%, #AAECFE 92.69%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 64.5rem;

  @media screen and (max-width: $mb){
    height: 34.2rem;
    top: 1.8rem;
  }
}

.sc__promo-bg--3 {
  height: 87.1rem;
  position: absolute;
  top: 56.4rem;
  left: 0;
  right: 0;
  z-index: 2;

  @media screen and (min-width: $desk){
    background: url("../img/promo-bg-3.svg") no-repeat center;
    background-size: contain;
  }

  @media screen and (max-width: $mb){
    height: 30.8rem;
    top: 33.9rem;
    background: url("../img/promo-bg-3-sm.svg") no-repeat center;
    background-size: contain;
  }
}

.sc__promo-bg--2 {
  height: 87.1rem;
  position: absolute;
  bottom: -7rem;
  left: 0;
  right: 0;
  z-index: 3;

  @media screen and (min-width: $desk){
    background: url("../img/promo-bg-2.svg") no-repeat center;
    background-size: contain;
  }

  @media screen and (max-width: $mb){
    background: url("../img/promo-bg-2-sm.svg") no-repeat center;
    background-size: contain;
    height: 50.2rem;
    bottom: -13rem;
  }
}

.promo__slogan {
  width: 49rem;
  margin: 0 auto 38.2rem;

  @media screen and (max-width: $mb){
    width: 28rem;
    margin: 0 auto 22.8rem;
  }

  img {
    display: block;
    width: 100%;
  }
}

.main__menu {
  @media screen and (max-width: $mb){
    margin-bottom: 4.2rem;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: $mb){
      flex-wrap: wrap;
    }

    li {
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 110%;

      @media screen and (max-width: $mb){
        width: 100%;
        font-size: 1.8rem;
        text-align: center;
      }

      a {
        color: #fff !important;
        cursor: pointer;
        will-change: color;
        transition: color 0.2s ease;

        &:hover {
          color: #FEC310 !important;
        }
      }

      &:not(:last-child){
        margin-right: 5rem;

        @media screen and (max-width: $mb){
          margin-bottom: 4rem;
          margin-right: 0;
        }
      }
    }
  }
}

.play__video {
  cursor: pointer;
  width: 10rem;
  height: 10rem;
  background: url("../img/play.svg") no-repeat center;
  background-size: contain;
  left: 50%;
  top: 50%;
  margin-left: -5rem;
  margin-top: -5rem;
  position: absolute;
  z-index: 2;
  will-change: transform;
  transition: transform 0.2s ease;

  @media screen and (max-width: $mb){
    width: 5.5rem;
    height: 5.5rem;
    margin-left: -2.75rem;
    margin-top: -2.75rem;
  }

  &:hover {
    transform: scale(0.95);
  }
}

.promo__video {
  margin-top: 17rem;
  margin-bottom: 4rem;
  width: 84rem;
  height: 52.2rem;
  border-radius: 3rem;
  border: 0.3rem solid #FFF;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background-color: #6EA040;
  overflow: hidden;

  @media screen and (max-width: $mb){
    margin-top: 16.2rem;
    width: 100%;
    height: 20rem;
    border-radius: 1.6rem;
    margin-bottom: 1.4rem;
    z-index: 5;
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}

.button {
  border: none;
  border-radius: 1.4rem;
  background: #D02E26;
  text-transform: uppercase;
  color: #fff !important;
  font-size: 1.6rem;
  line-height: 110%;
  padding: 0 1.8rem;
  height: 4.8rem;
  letter-spacing: -0.016rem;
  font-weight: 900;
  will-change: background-color;
  transition: background-color 0.2s ease;

  @media screen and (max-width: $mb){
    height: 4.8rem;
    padding: 0 1.8rem;
    font-size: 1.6rem;
    line-height: 1;
    letter-spacing: -0.016rem;
  }

  &--large {
    height: 7.2rem;
    padding: 0 3rem;
    font-size: 3rem;
    letter-spacing: -0.03rem;

    @media screen and (max-width: $mb){
      height: 4.8rem;
      padding: 0 1.8rem;
      font-size: 1.6rem;
      line-height: 1;
      letter-spacing: -0.016rem;
    }
  }

  &--prediction {
    height: 6.6rem;
    padding: 0 3rem;
    font-size: 2.2rem;
    letter-spacing: -0.022rem;
    line-height: 1;

    @media screen and (max-width: $mb){
      height: 4.8rem;
      padding: 0 1.4rem;
      font-size: 1.6rem;
      width: 100%;
      line-height: 1;
      letter-spacing: -0.016rem;
    }
  }

  &--medium {
    height: 5.8rem;
    padding: 0 3rem;
    font-size: 2rem;
    letter-spacing: -0.02rem;

    @media screen and (max-width: $mb){
      height: 4.8rem;
      padding: 0 1.8rem;
      font-size: 1.6rem;
      line-height: 1;
      letter-spacing: -0.016rem;
    }
  }

  &--custom {
    height: 6.8rem;
    text-align: center;
    padding: 0 2.4rem;
    font-size: 2rem;
    letter-spacing: -0.02rem;

    @media screen and (max-width: $mb){
      height: 4.8rem;
      padding: 0 1.8rem;
      font-size: 1.6rem;
      line-height: 1;
      letter-spacing: -0.016rem;
    }
  }

  &--white {
    background-color: #fff;
    color: #D02E26 !important;

    &:hover {
      color: #D02E26 !important;
      background-color: rgba(#fff, 0.7) !important;
    }
  }

  &[disabled] {
    background: #777;
    color: rgba(#fff, 0.6);

    .icon__sun {
      opacity: 0.6;
    }
  }

  &:not([disabled]){
    cursor: pointer;

    &:hover {
      background: #FF382F;
    }

    circle, path {
      fill: #FFD200;
    }
  }
}

.form__it {
  &:not(:last-child){
    margin-bottom: 1.2rem;

    @media screen and (max-width: $mb){
      margin-bottom: 1rem;
    }
  }
}

.form__list {
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
}

.form__label {
  color: rgba(0, 0, 0, 0.50);
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -0.018rem;

  @media screen and (max-width: $mb){
    font-size: 1.6rem;
    letter-spacing: -0.016rem;
  }
}

.attach-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file__clear {
  width: 1.8rem;
  height: 1.8rem;
  min-width: 1.8rem;
  position: relative;
  z-index: 4;
  margin-left: 2rem;
  cursor: pointer;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.result__card {
  img {
    display: block;
    width: 100%;
  }
}

.result__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1rem;

  @media screen and (max-width: $mb){
    grid-column-gap: 0.4rem;
  }
}

.attached__file {
  color: #000;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -0.018rem;
  height: 5.8rem;
  background-color: rgba(0, 0, 0, 0.10);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23333' stroke-width='2' stroke-dasharray='6%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  padding: 0 2rem;
  border-radius: 1rem;
}

.form__attach-btn {
  position: relative;
  border-radius: 1rem;
  height: 5.8rem;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23333' stroke-width='2' stroke-dasharray='6%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  color: #000;
  font-size: 1.8rem;
  margin: 0;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -0.018rem;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.10);
    border-radius: 1rem;
  }

  input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }
}

.icon-file {
  width: 2.6rem;
  height: 2.6rem;
  min-width: 2.6rem;
  margin-right: 1rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.form__select {
  position: relative;

  &.active {
    .form__select-dropdown {
      opacity: 1;
      visibility: visible;
    }

    .form__select-toggle {
      transform: rotateX(180deg);
    }
  }

  &-dropdown {
    position: absolute;
    top: 100%;
    margin-top: 0.2rem;
    left: 0;
    background: #fff;
    width: 100%;
    border-radius: 1.4rem;
    z-index: 5;
    box-shadow: 0 0 0.2rem rgba(#000, 0.5);
    opacity: 0;
    visibility: hidden;
    will-change: opacity, visibility;
    transition: opacity 0.2s ease, visibility 0.2s ease;

    .form__dropdown-list {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    .form__dropdown-it {
      padding: 0.8rem 2rem;
      line-height: 110%;
      letter-spacing: -0.018rem;
      font-size: 1.8rem;
      cursor: pointer;
      will-change: background-color, color;
      transition: color 0.2s ease, background-color 0.2s ease;

      @media screen and (max-width: $mb){
        padding: 0.6rem 1.6rem;
        letter-spacing: -0.016rem;
        font-size: 1.6rem;
      }

      &:hover, &.active {
        color: #D02E26;
      }

      &.active {
        background: rgba(#D02E26, 0.1);
      }
    }
  }

  &-input {
    height: 5.8rem;
    border-radius: 1.4rem;
    padding: 0 2rem;
    color: #000;
    width: 100%;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: -0.018rem;
    border: 2px solid #fff;
    background: #FFF;
    cursor: pointer;

    @media screen and (max-width: $mb){
      height: 4.8rem;
      font-size: 1.6rem;
      padding: 0 1.6rem;
    }
  }

  &-toggle {
    width: 1.4rem;
    min-width: 1.4rem;
    height: 0.9rem;
    will-change: transform;
    transition: transform 0.2s ease;

    svg {
      display: block;
      width: 100%;
      height: 100%;
      overflow: visible !important;
    }
  }
}

.form__field {
  input, textarea {
    height: 5.8rem;
    border-radius: 1.4rem;
    padding: 0 2rem;
    color: #000;
    width: 100%;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: -0.018rem;
    border: 2px solid #fff;
    background: #FFF;

    @media screen and (max-width: $mb){
      height: 4.8rem;
      font-size: 1.6rem;
      padding: 0 1.6rem;
      letter-spacing: -0.016rem;
    }

    &::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.5);
    }

    &:-moz-placeholder {
      color: rgba(0, 0, 0, 0.5);
    }

    &::-moz-placeholder {
      color: rgba(0, 0, 0, 0.5);
    }

    &:-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  textarea {
    height: 14rem;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
    resize: none;

    @media screen and (max-width: $mb){
      height: 11rem;
    }
  }

  &.has-error {
    input, textarea {
      border-color: #D02E26;
    }
  }
}

.error__message {
  color: #D02E26;
  font-size: 1.3rem;
  margin-top: 0.6rem;
  margin-bottom: 0;

  @media screen and (max-width: $mb){
    font-size: 1.2rem;
    margin-top: 0.2rem;
  }
}

.checkbox__wrap {
  label {
    font-weight: normal;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 0;

    input {
      opacity: 0;
      visibility: hidden;
      position: absolute;

      &:checked {
        & ~ .checkbox__decor {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
}

.form__group {
  &:not(:last-child){
    margin-bottom: 1.6rem;
  }
}

.checkbox__it {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}

.checkbox__decor {
  width: 2.8rem;
  height: 2.8rem;
  min-width: 2.8rem;
  background: #fff;
  margin-right: 1.4rem;
  border-radius: 0.8rem;
  border: 1px solid #fff;
  position: relative;
  cursor: pointer;

  @media screen and (max-width: $mb){
    width: 2.4rem;
    height: 2.4rem;
    min-width: 2.4rem;
    margin-right: 1.2rem;
  }

  &:after {
    content: '';
    position: absolute;
    background: url('../img/checked.svg') no-repeat center;
    background-size: contain;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    will-change: opacity;
    transition: opacity 0.2s ease;
  }
}

.has-error {
  .checkbox__decor {
    border-color: #E21919 !important;
  }
}

.checkbox__title {
  color: #5D2723;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 110%;

  @media screen and (max-width: $mb){
    font-size: 1.4rem;
  }

  a {
    color: #9E2B13;
    border-bottom: 1px solid rgba(#9E2B13, 0.5);
    will-change: border-color;
    transition: border-color 0.2s ease;

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
}

.footer {
  background: #FEAA41;
  border-radius: 3rem 3rem 0 0;
  position: relative;
  z-index: 7;

  @media screen and (max-width: $mb){
    z-index: 9;
  }

  .container {
    padding-top: 6rem;
    padding-bottom: 5.5rem;

    @media screen and (max-width: $mb){
      padding: 3rem;
    }
  }
}

.sc__promo-decor--1 {

  width: 44rem;
  height: 26.2rem;
  position: absolute;
  left: 50%;
  margin-left: -72rem;
  top: 56.2rem;
  z-index: 4;
  transform: rotate(-21.528deg);

  @media screen and (min-width: $desk){
    background: url("../img/promo-decor-1.svg") no-repeat center;
    background-size: contain;
  }

  @media screen and (max-width: $mb){
    width: 19.4rem;
    height: 11.5rem;
    margin-left: -17rem;
    top: 44.3rem;
    transform: rotate(-162.73deg);
    background: url("../img/promo-decor-1-sm.svg") no-repeat center;
    background-size: contain;
  }
}

.sc__promo-decor--2 {
  width: 29.5rem;
  height: 15.5rem;
  position: absolute;
  left: 50%;
  margin-left: -69rem;
  top: 17.2rem;
  z-index: 2;

  @media screen and (min-width: $desk){
    background: url("../img/cloud-1.svg") no-repeat center;
    background-size: contain;
  }

  @media screen and (max-width: $mb){
    width: 9.6rem;
    height: 5rem;
    margin-left: -22.1rem;
    top: 5.8rem;
    background: url("../img/cloud-1-mb.svg") no-repeat center;
    background-size: contain;
  }
}

.sc__promo-decor--3 {
  width: 23.8rem;
  height: 12.5rem;
  position: absolute;
  left: 50%;
  margin-left: 42rem;
  top: 14rem;
  z-index: 2;

  @media screen and (min-width: $desk){
    background: url("../img/cloud-2.svg") no-repeat center;
    background-size: contain;
  }

  @media screen and (max-width: $mb){
    width: 13rem;
    height: 6.9rem;
    margin-left: 4.6rem;
    top: 15.2rem;
    background: url("../img/cloud-1-mb.svg") no-repeat center;
    background-size: contain;
  }
}

.sc__promo-decor--4 {
  width: 14.3rem;
  height: 18.9rem;
  position: absolute;
  left: 0;
  top: 50rem;

  @media screen and (min-width: $desk){
    background: url("../img/tree-1.svg") no-repeat center;
    background-size: contain;
  }

  @media screen and (max-width: $mb){
    width: 7rem;
    height: 8.9rem;
    background: url("../img/tree-1-sm.svg") no-repeat center;
    background-size: contain;
    top: 28.9rem;
  }
}

.sc__promo-decor--5 {
  width: 20.3rem;
  height: 18.9rem;
  position: absolute;
  right: 0;
  top: 50rem;

  @media screen and (min-width: $desk){
    background: url("../img/tree-2.svg") no-repeat center;
    background-size: contain;
  }

  @media screen and (max-width: $mb){
    width: 4.3rem;
    height: 8.9rem;
    background: url("../img/tree-2-sm.svg") no-repeat center;
    background-size: contain;
    top: 29.7rem;
  }
}

.sc__promo-decor--6 {
  background: url("../img/decor-1.svg") no-repeat center;
  background-size: contain;
  width: 46.7rem;
  height: 47.6rem;
  position: absolute;
  left: 50%;
  top: 56.7rem;
  margin-left: -74.6rem;
  z-index: 2;
  transform: rotate(-9.895deg);

  @media screen and (max-width: $mb){
    width: 23.1rem;
    height: 23.5rem;
    top: 43rem;
    margin-left: -21rem;
  }
}

.sc__promo-decor--7 {
  background: url("../img/decor-2.svg") no-repeat center;
  background-size: contain;
  width: 38.2rem;
  height: 44.6rem;
  position: absolute;
  left: 50%;
  top: 55rem;
  margin-left: 42.5rem;
  z-index: 2;
  transform: rotate(15deg);

  @media screen and (max-width: $mb){
    top: 39.1rem;
    margin-left: -2.7rem;
    width: 25.8rem;
    height: 30.2rem;
  }
}

.promo__card {
  width: 20.2rem;
  position: absolute;
  z-index: 3;
  box-shadow: 0.1855rem 0.1855rem 0.371rem 0 rgba(0, 0, 0, 0.25);
  border-radius: 1.8rem;
  will-change: transform, margin-left, top;
  transition: transform 1s ease, margin-left 1s ease, top 1s ease;

  @media screen and (max-width: $mb){
    width: 11.6rem;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.25);
  }

  &--1 {
    left: 50%;
    top: 37.4rem;
    margin-left: -23.7rem;
    transform: rotate(4.579deg);
    z-index: 5;

    @media screen and (max-width: $mb){
      top: 24.5rem;
      margin-left: -12rem;
    }
  }

  &--2 {
    left: 50%;
    top: 37.4rem;
    margin-left: 4.2rem;
    transform: rotate(7.352deg);

    @media screen and (max-width: $mb){
      margin-left: 2rem;
      top: 24.3rem;
    }
  }

  &--3 {
    left: 50%;
    top: 33.6rem;
    margin-left: -9.3rem;
    transform: rotate(-7.676deg);
    z-index: 4;

    @media screen and (max-width: $mb){
      margin-left: -6rem;
      top: 22rem;
    }
  }

  img {
    display: block;
    width: 100%;
  }
}

.grass {
  background: url("../img/grass.svg") no-repeat center;
  background-size: contain;
  width: 7.4rem;
  height: 2.9rem;
  position: absolute;
  opacity: 0.44;
  z-index: 2;

  @media screen and (max-width: $mb){
    width: 4.5rem;
    height: 1.7rem;
  }

  &--1 {
    top: 65.2rem;
    left: 50%;
    margin-left: 29.4rem;

    @media screen and (max-width: $mb){
      margin-left: -16.5rem;
      top: 40.2rem;
    }
  }

  &--2 {
    top: 74.5rem;
    left: 50%;
    margin-left: -45.3rem;
  }

  &--3 {
    top: 75.7rem;
    left: 50%;
    margin-left: 48.8rem;

    @media screen and (max-width: $mb){
      margin-left: 11.2rem;
      top: 43.5rem;
    }
  }

  &--4 {
    top: 80.3rem;
    left: 50%;
    margin-left: -26.3rem;

    @media screen and (max-width: $mb){
      margin-left: 0.5rem;
      top: 49.9rem;
    }
  }

  &--5 {
    top: 78.8rem;
    left: 50%;
    margin-left: 12rem;

    @media screen and (max-width: $mb){
      margin-left: 8.2rem;
      top: 51.9rem;
    }
  }
}

.sc__steps {
  z-index: 6;

  &:before {
    content: '';
    position: absolute;
    background: url("../img/texture.jpg") repeat;
    top: -5rem;
    height: 110.5rem;
    left: 0;
    right: 0;
    z-index: 2;
    -webkit-mask-image: url("../img/bg-mask-1.svg");
    -webkit-mask-size: cover;

    @media screen and (max-width: $mb){
      -webkit-mask-size: contain;
      top: 0;
      height: 36rem;
    }
  }

  @media screen and (max-width: $mb){
    &:after {
      content: '';
      background: url("../img/texture.jpg") repeat;
      top: 16rem;
      bottom: -10rem;
      left: 0;
      right: 0;
      z-index: 2;
      position: absolute;
    }
  }

  .container {
    padding-top: 9rem;
    padding-bottom: 10rem;
    position: relative;
    z-index: 3;

    @media screen and (max-width: $mb){
      padding-top: 7rem;
      padding-bottom: 4rem;
    }
  }

  position: relative;

  .sc__header {
    margin-bottom: 6rem;

    @media screen and (max-width: $mb){
      margin-bottom: 2rem;
    }
  }
}

.steps__wrap {
  max-width: 98.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 11rem;

  @media screen and (max-width: $mb){
    flex-wrap: wrap;
    max-width: 100%;
    margin-bottom: 4rem;
  }
}

.bg-mask {
  position: absolute;
  left: 0;
  right: 0;
  height: 110.5rem;
  top: 0;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.sc__title {
  color: #000;
  text-align: center;
  font-size: 5rem;
  font-weight: 900;
  line-height: 100%;
  letter-spacing: -0.05rem;
  text-transform: uppercase;

  @media screen and (max-width: $mb){
    font-size: 2.4rem;
    letter-spacing: -0.024rem;
  }
}

.steps {
  &__it {
    @media screen and (max-width: $mb){
      width: 100%;
      position: relative;

      .button--custom {
        max-width: 22.9rem;
        margin-left: auto;
        margin-right: auto;
      }

      &:not(:last-child){
        margin-bottom: 4.4rem;

        &:after {
          content: '';
          width: 2px;
          height: 2.4rem;
          left: 50%;
          top: 100%;
          margin-left: -1px;
          background: #000;
          margin-top: 1rem;
          position: absolute;
        }
      }
    }

    @media screen and (min-width: $desk){
      &:nth-child(2){
        margin-left: 12.3rem;
        margin-right: 6.9rem;
      }

      &:nth-child(3){
        margin-right: 5.1rem;
      }

      &:not(:last-child){
        .steps {
          &__it {
            &-numb {
              &:after {
                content: '';
                width: 18.8rem;
                left: 100%;
                top: 50%;
                margin-top: -0.1rem;
                height: 0.2rem;
                background: #000;
                margin-left: 0.8rem;
                position: absolute;
              }
            }
          }
        }
      }
    }

    &-numb {
      width: 7rem;
      height: 7rem;
      border-radius: 50%;
      margin: 0 auto 2rem;
      background: #D02E26;
      color: #FFF;
      font-size: 2.8rem;
      font-style: normal;
      font-weight: 900;
      line-height: 100%;
      position: relative;

      @media screen and (max-width: $mb){
        width: 3.6rem;
        height: 3.6rem;
        margin-bottom: 0.8rem;
        font-size: 1.6rem;
      }
    }

    &-descr {
      color: #000;
      text-align: center;
      font-size: 2.4rem;
      font-weight: 400;
      line-height: 120%;

      @media screen and (max-width: $mb){
        font-size: 1.6rem;
        line-height: 100%;

        br {
          display: none;
        }
      }
    }
  }
}

.vg-decor-1 {
  position: absolute;
  background: url("../img/vg-decor-1.svg") no-repeat center;
  background-size: contain;
  width: 23.1rem;
  height: 16.8rem;
  left: 50%;
  margin-left: -55.4rem;
  top: -7rem;
  z-index: 3;

  @media screen and (max-width: $mb){
    width: 12.2rem;
    height: 8.8rem;
    margin-left: 8.7rem;
    top: 57.4rem;
  }
}

.vg-decor-2 {
  position: absolute;
  background: url("../img/vg-decor-2.svg") no-repeat center;
  background-size: contain;
  width: 19.4rem;
  height: 22.8rem;
  left: 50%;
  margin-left: 46.3rem;
  top: 0;
  z-index: 3;
  transform: rotate(-150.49deg);

  @media screen and (max-width: $mb){
    width: 8.1rem;
    height: 9.5rem;
    margin-left: -18rem;
    top: -5.5rem;
  }
}

.vg-decor-3 {
  position: absolute;
  background: url("../img/vg-decor-3.svg") no-repeat center;
  background-size: contain;
  width: 6.7rem;
  height: 22.9rem;
  left: 50%;
  margin-left: 54.4rem;
  top: -8rem;
  z-index: 3;
  transform: rotate(-120deg);

  @media screen and (max-width: $mb){
    width: 4.2rem;
    height: 14.1rem;
    margin-left: -17rem;
    top: -5rem;
  }
}

.vg-decor-4 {
  position: absolute;
  background: url("../img/vg-decor-4.svg") no-repeat center;
  background-size: contain;
  width: 29.7rem;
  height: 30.9rem;
  left: 50%;
  margin-left: -72.3rem;
  top: 57rem;
  z-index: 4;
  transform: rotate(4.76deg);

  @media screen and (max-width: $mb){
    margin-left: -23.7rem;
    width: 17.4rem;
    height: 18.1rem;
    top: 24.4rem;
  }
}

.vg-decor-5 {
  position: absolute;
  background: url("../img/vg-decor-5.png") no-repeat center;
  background-size: contain;
  width: 15.7rem;
  height: 18.5rem;
  left: 50%;
  margin-left: 40rem;
  top: -5rem;
  z-index: 4;
  transform: rotate(-30deg);

  @media screen and (max-width: $mb){
    width: 6.6rem;
    height: 7.8rem;
    margin-left: 9rem;
    top: -3rem;
  }
}

.vg-decor-6 {
  position: absolute;
  background: url("../img/vg-decor-6.svg") no-repeat center;
  background-size: contain;
  width: 15.5rem;
  height: 9.7rem;
  left: 50%;
  margin-left: -34.5rem;
  top: -3rem;
  z-index: 4;

  @media screen and (max-width: $mb){
    width: 8.6rem;
    height: 5.3rem;
    margin-left: 1.3rem;
  }
}

.footer__nav {
  margin-bottom: 4rem;

  @media screen and (max-width: $mb){
    margin-bottom: 2.4rem;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;

    @media screen and (max-width: $mb){
      flex-wrap: wrap;
    }

    li {
      color: #000;
      text-align: center;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 110%;

      @media screen and (max-width: $mb){
        width: 100%;
        font-size: 1.4rem;
      }

      a {
        color: #000 !important;
        will-change: color;
        cursor: pointer;
        transition: color 0.2s ease;

        &:hover {
          color: rgba(#000, 0.5) !important;
        }
      }

      &:not(:last-child){
        margin-right: 6rem;

        @media screen and (max-width: $mb){
          margin-bottom: 1.4rem;
          margin-right: 0;
        }
      }
    }
  }
}

.footer__rights {
  max-width: 129rem;
  margin-right: auto;
  margin-left: auto;
  color: rgba(0, 0, 0, 0.60);
  text-align: center;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;

  @media screen and (max-width: $mb){
    max-width: 29.8rem;
    font-size: 1.2rem;
  }
}

.vg-decor-7 {
  position: absolute;
  width: 24rem;
  height: 21.3rem;
  left: 50%;
  margin-left: 52.4rem;
  top: 24.4rem;
  z-index: 4;
  transform: rotate(75deg);

  @media screen and (min-width: $desk){
    background: url("../img/vg-decor-7.svg") no-repeat center;
    background-size: contain;
  }

  @media screen and (max-width: $mb){
    margin-left: -20.2rem;
    width: 11.4rem;
    height: 10.1rem;
    top: -5rem;
    transform: rotate(105deg);
    background: url("../img/vg-decor-7-sm.svg") no-repeat center;
    background-size: contain;
  }
}

.vg-decor-8 {
  position: absolute;
  background: url("../img/vg-decor-8.svg") no-repeat center;
  background-size: contain;
  width: 8.2rem;
  height: 27.7rem;
  left: 50%;
  margin-left: -24.9rem;
  top: 32.3rem;
  z-index: 4;
  transform: rotate(36.704deg);

  @media screen and (max-width: $mb){
    width: 4.2rem;
    height: 14.1rem;
    margin-left: 13.5rem;
    top: 18.2rem;
    transform: rotate(-135.932deg);
  }
}

.prizes__list {
  max-width: 108rem;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 6rem;

  @media screen and (max-width: $mb){
    padding: 0 1rem;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 2.5rem;
  }

  &--modal {
    grid-column-gap: 2.4rem;
    margin-bottom: 1.7rem;

    @media screen and (max-width: $mb){
      grid-row-gap: 2rem;
    }

    .prizes {
      &__it {
        &-img {
          border-radius: 1.4rem;
          box-shadow: 1.4rem 1.4rem 4rem rgba(0, 0, 0, 0.20);

          @media screen and (max-width: $mb){
            box-shadow: 0.8rem 1.6rem 4rem rgba(0, 0, 0, 0.15);
          }
        }

        &-bottom {
          margin-top: -1.4rem;
        }

        &-count {
          border-radius: 1.4rem;
          border: 1px solid #000;
          background: #FEAA41;
          padding: 0 1.6rem;
          height: 2.8rem;
          color: #000;
          text-align: center;
          font-size: 1.2rem;
          line-height: 100%;
        }
      }
    }
  }
}

.prizes {
  &__it {
    position: relative;

    &-img {
      border-radius: 2.4rem;
      overflow: hidden;
      box-shadow: 3.6843rem 3.6843rem 10.2341rem rgba(0, 0, 0, 0.25);

      @media screen and (max-width: $mb){
        border-radius: 1.3rem;
        box-shadow: 0.8rem 1.6rem 4rem rgba(0, 0, 0, 0.15);
      }

      img {
        display: block;
        width: 100%;
      }
    }

    &-bottom {
      margin-top: -2.4rem;

      @media screen and (max-width: $mb){
        margin-top: -1.5rem;
      }
    }

    &-count {
      border-radius: 2.4rem;
      border: 2px solid #000;
      background: #FEAA41;
      padding: 0 2.5rem;
      height: 4.8rem;
      color: #000;
      text-align: center;
      font-size: 2.0468rem;
      line-height: 100%;

      @media screen and (max-width: $mb){
        height: 3rem;
        border-radius: 1.6rem;
        font-size: 1.4rem;
        border-width: 1px;
        padding: 0 1.6rem;
      }
    }
  }
}

.sc__prediction {
  position: relative;
  z-index: 6;

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 103.1rem;

    @media screen and (max-width: $mb){
      height: 99rem;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .container {
    padding-top: 10rem;
    position: relative;
    padding-bottom: 10rem;
    z-index: 3;

    @media screen and (max-width: $mb){
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  }

  .sc__header {
    margin-bottom: -4.5rem;

    @media screen and (max-width: $mb){
      margin-bottom: -2rem;
    }
  }

  .sc__title {
    height: 9rem;
    border-radius: 4.5rem;
    background: #D02E26;
    padding: 0 3.6rem;
    color: #fff;

    @media screen and (max-width: $mb){
      height: 4rem;
      border-radius: 2rem;
      font-size: 2rem;
      letter-spacing: -0.02rem;
      padding: 0 2rem;
    }
  }
}

.prediction{
  &__wrap {
    max-width: 117.8rem;
    margin-right: auto;
    margin-left: auto;
    background: #6EA040;
    border: 0.3rem solid #000;
    border-radius: 3rem;
    padding-top: 6.5rem;
    padding-bottom: 5.2rem;
    padding-right: 1rem;
    padding-left: 1rem;

    @media screen and (max-width: $mb){
      border-radius: 2rem;
      margin-left: -1rem;
      margin-right: -1rem;
      border: 0.4rem solid #D02E26;
      padding-top: 4rem;
      padding-bottom: 2.6rem;
    }
  }

  &__subtitle {
    color: #FFF;
    text-align: center;
    font-size: 2.8rem;
    font-weight: 900;
    line-height: 120%;
    margin-bottom: 3.2rem;

    @media screen and (max-width: $mb){
      font-size: 1.6rem;
      line-height: 120%;
      margin-bottom: 1.6rem;
      max-width: 24rem;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__rows {
    margin-bottom: 5.1rem;

    @media screen and (max-width: $mb){
      margin-bottom: 1.6rem;
    }
  }

  &__row {
    @media screen and (max-width: $mb){
      padding: 0;
    }

    &:not(:last-child){
      margin-bottom: 1.6rem;
    }

    &-cards {
      width: 55.5rem;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 3rem;

      @media screen and (max-width: $mb){
        width: 100%;
        grid-column-gap: 1rem;
      }
    }

    &-card {
      box-shadow: 1.656px 1.656px 3.312px 0 #DC9B00;
      border-radius: 1.4rem;
      cursor: pointer;
      will-change: box-shadow;
      transition: box-shadow 0.2s ease;
      position: relative;

      @media screen and (max-width: $mb){
        box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.25);
        border-radius: 0.9rem;
      }

      &.active {
        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border: 0.75rem solid #D02E26;
        opacity: 0;
        will-change: opacity;
        transition: opacity 0.2s ease;
        border-radius: 1.4rem;

        @media screen and (max-width: $mb){
          border-radius: 0.9rem;
          border: 0.45rem solid #D02E26;
        }
      }

      &:hover {
        box-shadow: 0 0 0 0 #DC9B00;
      }

      img {
        display: block;
        width: 100%;
      }
    }

    &-header {
      width: 23.6rem;
      border-radius: 2.9rem;
      border: 4px solid #D02E26;
      background: #D02E26;
      margin-right: 6rem;

      @media screen and (max-width: $mb){
        border-radius: 1.6rem;
        border: 3px solid #D02E26;
        margin-bottom: 0.6rem;
        margin-left: 0;
        margin-right: auto;
        width: auto;
        padding-right: 1.2rem;
      }
    }

    &-title {
      height: 4.7rem;
      background: #fff;
      border-radius: 2.4rem;
      padding: 0 1.6rem;
      color: #000;
      text-align: center;
      font-size: 2.1726rem;
      font-weight: 400;
      line-height: 1;
      margin-right: 1.6rem;

      @media screen and (max-width: $mb){
        font-size: 1.18rem;
        height: 2.5rem;
        padding: 0 0.9rem;
        color: #D02E26;
        margin-right: 0.8rem;
      }

      &-2 {
        color: #FFF;
        font-size: 3.1037rem;
        font-weight: 900;
        line-height: 110%;
        text-transform: uppercase;

        @media screen and (max-width: $mb){
          font-size: 1.68rem;
        }
      }
    }
  }
}

.icon__sun {
  width: 4.4rem;
  height: 4.4rem;
  margin-left: 1.4rem;
  min-width: 4.4rem;

  circle, path {
    will-change: fill;
    transition: fill 0.2s ease;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.delivery__wrap {
  max-width: 108rem;
  margin-right: auto;
  margin-left: auto;

  .sc__header {
    margin-bottom: 5rem;

    @media screen and (max-width: $mb){
      margin-bottom: 2rem;
    }
  }

  .button {
    min-width: 22.5rem;

    @media screen and (max-width: $mb){
      min-width: 20rem;
    }
  }

  .sc__subtitle {
    margin-bottom: 3rem;
    color: #000;

    @media screen and (max-width: $mb){
      margin-bottom: 2rem;
      text-align: center;
      max-width: 22rem;
      margin-left: auto;
      margin-right: auto;

      br {
        display: none;
      }
    }
  }

  @media screen and (min-width: $desk){
    .sc__title {
      text-align: left;
    }
  }
}

.sc__winners {
  position: relative;

  .container {
    position: relative;
    z-index: 3;
    padding-top: 9rem;
    padding-bottom: 20rem;

    @media screen and (max-width: $mb){
      padding-top: 4rem;
      padding-bottom: 10rem;
    }
  }

  .sc__title {
    color: #fff;
  }

  .sc__header {
    margin-bottom: 4rem;

    @media screen and (max-width: $mb){
      margin-bottom: 2rem;
    }
  }

  @media screen and (max-width: $mb){
    z-index: 7;

    .sc__subtitle {
      max-width: 28.6rem;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 1.6rem;
    }
  }
}

.winners__bg-top {
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 10rem;
  left: 0;
  right: 0;

  svg {
    display: block;
    width: 100%;
    height: 103.2rem;

    @media screen and (max-width: $mb){
      height: 85.2rem;
    }
  }
}

.winners__bg-bottom {
  position: absolute;
  overflow: hidden;
  top: 10rem;
  bottom: 0;
  left: 0;
  right: 0;

  svg {
    display: block;
    width: 100%;
    height: 103.2rem;

    @media screen and (max-width: $mb){
      height: 85.2rem;
    }
  }
}

.sc__subtitle {
  margin-bottom: 4rem;
  color: #FFF;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 120%;

  @media screen and (max-width: $mb){
    font-size: 1.6rem;
  }

  a {
    color: #fff !important;
    border-bottom: 1px solid #fff;
    will-change: border-color;
    transition: border-color 0.2s ease;

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
}

.winners__wrap {
  @media screen and (min-width: $desk){
    background: #F4EDE6;
    border-radius: 3rem;
    max-width: 117.8rem;
    margin-left: auto;
    margin-right: auto;
    border: 0.3rem solid #000;
    padding: 4rem 11.5rem 4.8rem;
  }
}

.win__search {
  max-width: 74.6rem;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  margin-bottom: 4rem;

  @media screen and (max-width: $mb){
    margin-bottom: 1.6rem;
  }
}

.tb__empty {
  border-radius: 1rem;
  background: rgba(0, 0, 0, 0.05);
  padding: 4rem;
}

@media screen and (min-width: $desk){
  .tb__row {
    display: flex;
  }
}

.tb__body {
  position: relative;

  &:before {
    right: -5.6rem;
    width: 0.6rem;
    top: 0;
    bottom: 0;
    border-radius: 0.3rem;
    position: absolute;
    background: rgba(0, 0, 0, 0.10);
    content: '';
    z-index: 0;

    @media screen and (max-width: $mb){
      right: -1.2rem;
      width: 0.4rem;
      top: 2rem;
      bottom: 2rem;
    }
  }

  &--auto {
    &:before {
      display: none;
    }
  }
}

.tb__body-scroll {
  height: 34rem;
  margin-right: -5.6rem;
  position: relative;

  @media screen and (max-width: $mb){
    height: 50rem;
    margin-right: -1.2rem;
  }

  &--auto {
    height: auto;
  }

  &-inner {
    padding-right: 5.6rem;

    @media screen and (max-width: $mb){
      padding-right: 3.2rem;
      padding-left: 2rem;
    }
  }
}

.tb__th {
  color: #5D2723;
  font-size: 2rem;
  font-style: normal;
  font-weight: 800;
  line-height: 2.25rem;
  text-transform: uppercase;
}

@media screen and (min-width: $desk){
  .tb__body {
    .tb__row {
      &:nth-child(2n + 1){
        border-radius: 1rem;
        background: rgba(0, 0, 0, 0.05);
      }

      &:not(:last-child){
        margin-bottom: 0.5rem;
      }
    }
  }
}

.tb__td {
  color: #5D2723;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.25rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  @media screen and (max-width: $mb){
    font-size: 1.6rem;
    line-height: 110%;
    padding: 0;
    position: relative;
    z-index: 2;
  }
}

.tb__header {
  margin-bottom: 1.6rem;
}

@media screen and (min-width: $desk){
  .tb__col {
    padding-right: 1rem;

    &:nth-child(1), &:nth-child(2) {
      width: 40%;
      min-width: 40%;
    }

    &:nth-child(1) {
      padding-left: 2.4rem;
    }

    &:nth-child(3){
      width: 20%;
      min-width: 20%;
      padding-right: 2.4rem;
    }
  }
}

.tb__empty-title {
  color: #5D2723;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.25rem;

  @media screen and (max-width: $mb){
    text-align: center;
    font-size: 1.6rem;
    line-height: 110%;
  }
}

.win__search-field {
  position: relative;

  input {
    width: 100%;
    display: block;
    height: 5.8rem;
    border: 1px solid #fff;
    background: #fff;
    border-radius: 14px;
    padding-left: 2rem;
    padding-right: 5rem;
    font-size: 1.8rem;
    color: #000;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: -0.018rem;

    @media screen and (max-width: $mb){
      height: 4.8rem;
      font-size: 1.6rem;
      padding-left: 1.6rem;
      padding-right: 4.4rem;
    }

    &::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.5);
    }

    &:-moz-placeholder {
      color: rgba(0, 0, 0, 0.5);
    }

    &::-moz-placeholder {
      color: rgba(0, 0, 0, 0.5);
    }

    &:-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &.has-error {
    input, textarea {
      border-color: #D02E26;
    }
  }
}

.winners__text {
  font-size: 2.4rem;
  line-height: 1;
  color: #5D2723;

  @media screen and (max-width: $mb){
    font-size: 1.6rem;
  }
}

.winners__wrap--empty {
  padding: 0;
  height: 50rem;

  @media screen and (max-width: $mb){
    background: #F4EDE6;
    border-radius: 3rem;
    height: 24rem;
    margin-left: auto;
    margin-right: auto;
    border: 0.2rem solid #000;
  }
}

.winners__icon {
  width: 12rem;
  margin: 0 auto 2.4rem;

  @media screen and (max-width: $mb){
    width: 6.4rem;
    margin: 0 auto 1.6rem;
  }

  img {
    display: block;
    width: 100%;
  }
}

.promo__prize {
  position: absolute;
  left: 50%;
  width: 71.9rem;
  margin-left: -36rem;
  top: 23rem;
  z-index: 3;
  transform: scale(0);
  will-change: transform;
  transition: transform 1s ease;

  @media screen and (max-width: $mb){
    width: 36rem;
    margin-left: -18rem;
    top: 18rem;
  }

  img {
    display: block;
    width: 100%;
  }
}

.promo__video-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  & > div {
    width: 100% !important;
    height: 100% !important;

    & > div {
      width: 100% !important;
      height: 100% !important;
    }
  }

  iframe, video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none !important;
  }
}

.win__search {
  position: relative;

  .error__message {
    position: absolute;
    top: 100%;
  }
}

.btn__search {
  position: absolute;
  background: transparent;
  border: none;
  cursor: pointer;
  width: 2.2rem;
  height: 2.2rem;
  right: 2rem;
  top: 50%;
  margin-top: -1.1rem;
  will-change: opacity;
  transition: opacity 0.2s ease;

  @media screen and (max-width: $mb){
    width: 1.8rem;
    height: 1.8rem;
    margin-top: -0.9rem;
  }

  &:hover {
    opacity: 0.75;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    overflow: visible;
  }
}

.sc__delivery {
  position: relative;
  margin-top: -10rem;

  @media screen and (max-width: $mb){
    margin-top: -5rem;
    z-index: 8;
  }

  &:before {
    content: '';
    position: absolute;
    background: url("../img/texture.jpg") repeat;
    top: 0;
    height: 59.5rem;
    left: 0;
    right: 0;
    z-index: 2;

    @media screen and (min-width: $desk){
      -webkit-mask-image: url("../img/bg-mask-2.svg");
      -webkit-mask-size: cover;
    }

    @media screen and (max-width: $mb){
      -webkit-mask-image: url("../img/bg-mask-2-sm.svg");
      -webkit-mask-size: cover;
      height: 63.6rem;
    }

  }

  .container {
    padding-bottom: 13.6rem;
    padding-top: 13.2rem;
    position: relative;
    z-index: 3;

    @media screen and (max-width: $mb){
      padding-bottom: 25rem;
      padding-top: 5rem;
    }
  }
}


.delivery__img {
  width: 52.7rem;
  position: absolute;
  margin-left: -8.8rem;
  left: 50%;
  top: 4.8rem;
  z-index: 2;

  @media screen and (max-width: $mb){
    width: 25rem;
    margin-left: -12.5rem;
    top: 22.9rem;
  }

  img {
    display: block;
    width: 100%;
  }
}

.form--loading, .tb--loading {
  position: relative;

  .form__group, .tb__body-it, .code__form-submit, & > textarea, & > .form__field, & > .form__list, .codes__it, .tb__body, .win__search-field {
    opacity: 0.2;
  }

  &:before{
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 8;
    position: absolute;
  }

  &:after {
    content: '';
    background: url("../img/loader.svg") no-repeat center;
    background-size: contain;
    width: 9.6rem;
    height: 9.6rem;
    left: 50%;
    top: 50%;
    position: absolute;
    margin-left: -4.8rem;
    margin-top: -4.8rem;
    z-index: 10;
  }
}

.result__btns {
  .button {
    padding: 0 2.6rem;
    white-space: nowrap;
    font-size: 2rem;
  }
}

.recipe__box {
  padding-top: 2rem;
  padding-bottom: 1rem;

  @media screen and (max-width: $mb){
    padding-top: 0;
    padding-bottom: 0;
  }

  &-img {
    width: 32.4rem;
    min-width: 32.4rem;
    margin-right: 3rem;

    @media screen and (max-width: $mb){
      margin-bottom: 2rem;
      margin-right: 0;
      width: 100%;
      min-width: 100%;
    }

    img {
      display: block;
      width: 100%;
      border-radius: 2rem;

      @media screen and (min-width: $desk){
        height: 20rem;
        object-fit: cover;
      }
    }
  }
}

.btn__download {
  height: 4.8rem;
  padding: 0 1.8rem;
  border-radius: 1.4rem;
  border: 1.5px solid rgba(0, 0, 0, 0.30);
  color: #000 !important;
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 900;
  line-height: 110%;
  letter-spacing: -0.016rem;
  text-transform: uppercase;
  will-change: color, border-color, background-color;
  transition: color 0.2s ease, border-color 0.2s ease, background-color 0.2s ease;

  &:hover {
    color: #D02E26 !important;
    border-color: #D02E26;
    background-color: #fff;

    .icon__pdf {
      background-color: #D02E26;
      color: #fff;
    }
  }
}

.share__links{
   margin-top: 3rem;

  @media screen and (max-width: $mb){
    margin-top: 1rem;
  }
}

.share__link {
  width: 6rem;
  height: 6rem;
  cursor: pointer;
  min-width: 6rem;
  border-radius: 50%;

  @media screen and (max-width: $mb){
    width: 4rem;
    height: 4rem;
    min-width: 4rem;
  }

  &:not(:last-child) {
    margin-right: 2rem;
  }

  &:hover {
    circle {
      fill: #D02E26;
    }
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    overflow: visible !important;
  }

  circle {
    will-change: fill;
    transition: fill 0.2s ease;
  }
}

.icon__pdf {
  color: #000;
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 900;
  line-height: 110%;
  letter-spacing: -0.012rem;
  text-transform: uppercase;
  margin-left: 1rem;
  border-radius: 0.8rem;
  background: #F4EDE6;
  padding: 0.6rem 0.8rem;
  will-change: background-color, color;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.recipe__box-title {
  color: #000;
  font-size: 2.2rem;
  font-weight: 900;
  line-height: 110%;
  text-transform: uppercase;
  margin-bottom: 2rem;

  @media screen and (max-width: $mb){
    font-size: 1.6rem;
    text-align: center !important;
    width: 100%;
  }
}

.recipe__box-icon {
  width: 6rem;
  height: 6rem;
  min-width: 6rem;
  margin-bottom: 2rem;

  @media screen and (max-width: $mb){
    width: 5rem;
    height: 5rem;
    min-width: 5rem;
    margin-left: auto;
    margin-right: auto;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    overflow: visible;
  }
}

.cookies {
  position: fixed;
  bottom: 0;
  padding: 2.8rem 2rem;
  z-index: 1000;
  width: 100%;
  left: 0;

  @media screen and (max-width: $mb){
    padding: 1rem;
  }

  &__box {
    border-radius: 1.4rem;
    border: 2px solid #000;
    background: #F4EDE6;
    padding: 1.8rem 2.6rem;
    width: 90rem;

    @media screen and (max-width: $mb){
      padding: 1.5rem;
      width: 100%;
      flex-wrap: wrap;
      justify-content: center !important;
    }
  }

  &__txt {
    color: #000;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;

    @media screen and (max-width: $mb){
      font-size: 1.1rem;
      margin-bottom: 1.1rem;
      line-height: 120%;
    }
  }

  &__btn {
    margin-left: 3rem;

    @media screen and (max-width: $mb){
      margin-left: auto;
      margin-right: auto;
    }
  }

  .button {
    min-width: 13.8rem;

    @media screen and (max-width: $mb){
      min-width: 12rem;
      height: 3.8rem;
      padding: 0 1.8rem;
      font-size: 1.4rem;
      line-height: 1;
      letter-spacing: -0.014rem;
      border-radius: 1.2rem;
    }
  }
}

.end__title {
  font-size: 5rem;
  line-height: 1;
  color: #D02E26;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: -0.01rem;

  @media screen and (max-width: $mb){
    font-size: 2.3rem;
  }
}

@media screen and (min-width: $desk){
  .mb-only {
    display: none !important;
  }
}

@media screen and (min-width: $desk) and (min-aspect-ratio: 13/7) {
  html {
    font-size: 0.64vw;
  }

  .sc__promo-bg--3 {
    height: 94.1rem;
  }

  .sc__promo-bg--2 {
    height: 94.1rem;
    bottom: -14rem;
  }

  .sc__promo-decor--6 {
    margin-left: -80.6rem;
  }

  .sc__promo-decor--7 {
    margin-left: 44.5rem;
  }

  .promo__slogan {
    margin: 0 auto 36.2rem;
  }
}

@media screen and (max-width: $mb){
  .desk-only {
    display: none !important;
  }

  html {
    font-size: 2.777778vw;
  }
  
  .main__nav {
    display: none;
    height: 100vh;
    left: 0;
    right: 0;
    position: fixed;
    background: #6EA040;
    animation-duration: 0.4s;
    top: 0;
    align-items: center;
    justify-content: center;

    &-bl {
      padding-bottom: 4rem;
    }
    
    &.active {
      display: flex;
    }
  }

  .nav__bars {
    width: 3.2rem;
    height: 3.2rem;
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      svg {
        width: 2.6rem;
        height: 2.6rem;
      }
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .prediction__row {
    flex-wrap: wrap;
  }

  .winners__ct {
    border-radius: 2rem;
    border: 2px solid #000;
    background: #F4EDE6;
  }

  .tb__body {
    padding-top: 2rem;
    padding-bottom: 2rem;
    .tb__row {
      position: relative;

      &:nth-child(2n){
        &:before {
          content: '';
          position: absolute;
          border-radius: 1rem;
          top: -1.6rem;
          bottom: -1.6rem;
          left: -1.2rem;
          right: -1.2rem;
          background: rgba(0, 0, 0, 0.05);
        }
      }

      &:not(:last-child){
        margin-bottom: 3.2rem;
      }
    }

    .tb__col {
      display: flex;

      &:before {
        content: attr(data-title);
        text-transform: uppercase;
        color: #5D2723;
        font-size: 1.5rem;
        font-weight: 900;
        line-height: 110%;
        min-width: 7.1rem;
        width: 7.1rem;
      }

      &:not(:last-child){
        margin-bottom: 0.6rem;
      }
    }
  }

  .delivery__wrap {
    .d-flex {
      justify-content: center;
    }
  }

  .vg-decor-9 {
    position: absolute;
    background: url("../img/vg-decor-9.png") no-repeat center;
    background-size: contain;
    width: 8.1rem;
    height: 6.9rem;
    left: 50%;
    margin-left: 9rem;
    top: 17rem;
    z-index: 4;
  }

  .nav__decor--1 {
    background: url("../img/nav-decor-1.svg") no-repeat center;
    background-size: contain;
    width: 6.4rem;
    height: 7rem;
    left: 50%;
    margin-left: -9rem;
    transform: rotate(45deg);
    bottom: 4rem;
    position: absolute;
  }

  .nav__decor--2 {
    background: url("../img/nav-decor-2.svg") no-repeat center;
    background-size: contain;
    width: 11.5rem;
    height: 13.2rem;
    left: 50%;
    margin-left: 12rem;
    top: 27.1rem;
    position: absolute;
  }

  .nav__decor--3 {
    background: url("../img/vg-decor-4.svg") no-repeat center;
    background-size: contain;
    width: 15.1rem;
    height: 15.7rem;
    left: 50%;
    position: absolute;
    margin-left: -22.3rem;
    top: 3.5rem;
    z-index: 4;
    transform: rotate(4.76deg);
  }

  .nav__decor--4 {
    background: url("../img/nav-decor-4.svg") no-repeat center;
    background-size: contain;
    width: 4rem;
    height: 13.4rem;
    left: 50%;
    position: absolute;
    margin-left: 13rem;
    top: 7.2rem;
    z-index: 4;
    transform: rotate(144.168deg);
  }

  .result__btns {
    flex-wrap: wrap;

    .button {
      width: 100%;
      font-size: 1.6rem;

      &:not(:last-child){
        margin-bottom: 1rem;
      }
    }
  }

  .recipe__box {
    flex-wrap: wrap;
  }

  .recipe__box-ct {
    width: 100%;
  }

  .recipe__box-action {
    justify-content: center;
    width: 100%;
  }

  .btn__back {
    position: absolute;
    width: 1.3rem;
    height: 2.2rem;
    cursor: pointer;
    left: 0;
    top: 0;

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }

    &:before {
      content: '';
      left: -0.6rem;
      right: -0.6rem;
      bottom: -0.3rem;
      top: -0.3rem;
      position: absolute;
    }
  }

  .w-100-mb {
    width: 100% !important;
  }
}